import "./style.scss";
import React, { memo } from "react";
import Images from "../../Assets/Images/ImageHelper/index";
import { Nav, Navbar, NavItem, NavbarBrand } from "reactstrap";

const TopBar = () => {
  return (
    <div id="TopBar" className="m-0 p-0 bg-darkgreen">
      <Navbar className="navbar shadow-sm transition">
        <NavbarBrand>
          <div>
            <img src={Images.LOGO_IMG} alt="logo" width={"150px"} />
          </div>
        </NavbarBrand>
        <Nav className="search gap-4">
          <NavItem className="NavItemsss">
            {/* <InputField
              type={"search"}
              name={"Searchbox"}
              icon={<IoIosSearch size={20} className="mb-2 text-warning" />}
              placeholder={"Search...."}
              className={"searchbox px-5 py-2 form-control-lg w-100"}
            /> */}
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default memo(TopBar);
