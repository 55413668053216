let Images = {
  INSTA_LOGO: require("../Gallery/instaLogo.png"),
  LOGO_IMG: require("../../Images/Gallery/logo.png"),
  Product_IMG: require("../Gallery/product-green.png"),
  Gift_Image: require("../../Images/Gallery/gift.png"),
  Shear_Image: require("../../Images/Gallery/shear.png"),
  Order_Image: require("../../Images/Gallery/order.png"),
  PAW_Image: require("../../Images/Gallery/pawicon.png"),
  PetHome_Image: require("../../Images/Gallery/home.png"),
  USER_DUMMY_IMG: require("../../Images/Gallery/User.png"),
  FACEBOOK_LOGO: require("../Gallery/facebookLogo (1).png"),
  THANKYOU_PNG: require("../../Images/Gallery/thankyou.png"),
  SIDE_PANEL_IMG: require("../../Images/Gallery/Sidebar_Logo.png"),
};

export default Images;
